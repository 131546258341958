import { cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  SelectBox,
  SelectBoxOption,
} from '@imtbl/design-system';
import { ImmutableMethodParams } from '@imtbl/imx-sdk';
import { chooseDefaultSortOptions } from 'components/SortAssets/helpers';
import { FEATURE_FLAG } from 'config/feature-flags';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import { scrollToTop } from 'utils/scrollToTop';

import { useURLSearchParams } from '../../hooks/useURLSearchParams.hook';
import {
  CustomOrderFilters,
  dateAcquiredOptions,
  dateOptions,
  nameOptions,
  OptionLabel,
  priceOptions,
  recentlySoldFilterOptions,
  sellNameOptions,
  SortContext,
} from './constants';
import * as styles from './SortAssets.styles';

export interface SortAssetsControlProps extends BaseComponentPropTypes {
  context: SortContext;
}

export type DefaultSortOption = {
  asString: string;
  order_by: string;
  direction: ImmutableMethodParams.ImmutableSortOrder;
};

export function SortAssets({ context }: SortAssetsControlProps) {
  const searchParams = useURLSearchParams();
  const isMarketplace = context === SortContext.MARKETPLACE;

  const enableRecentlySoldFilterFlag = useFeatureFlag(
    FEATURE_FLAG.ENABLE_RECENTLY_SOLD_FILTER,
  );

  const marketplaceSortOptions = enableRecentlySoldFilterFlag
    ? [
        ...recentlySoldFilterOptions,
        ...sellNameOptions,
        ...dateOptions,
        ...priceOptions,
      ]
    : [...sellNameOptions, ...dateOptions, ...priceOptions];

  const options = isMarketplace
    ? marketplaceSortOptions
    : [...nameOptions, ...dateAcquiredOptions];

  const previousSort = searchParams.getSort();
  const previousCustomFilter = searchParams.getCustomFilter();

  let selectedOption = 0;
  let defaultSort = chooseDefaultSortOptions(context).asString;
  if (previousSort) {
    defaultSort = `${previousSort.order_by}#${previousSort.direction}`;
    if (previousCustomFilter && enableRecentlySoldFilterFlag) {
      defaultSort += `#${previousCustomFilter}`;
    }
  }

  // @NOTE: if there is no currently selected sort option,
  // set the default sort option to the URL params
  selectedOption = options.findIndex(
    (item: SelectBoxOption<string>) => item.value === defaultSort,
  );

  const handleChange = (option: SelectBoxOption<string>) => {
    const { value, label } = option;
    const parsedValue =
      typeof value === 'string' ? value.split('#') : [`${value}`];
    const newSelectedOption = options.findIndex(
      (item: SelectBoxOption<string>) => item.value === value,
    );
    if (newSelectedOption !== selectedOption) {
      if (label == OptionLabel.LABEL_NAME_RECENTLY_SOLD) {
        searchParams.setCustomFilter(CustomOrderFilters.RecentlySold);
      } else {
        searchParams.setSort({
          order_by: parsedValue[0],
          direction: parsedValue[1] as ImmutableMethodParams.ImmutableSortOrder,
        });
      }
      scrollToTop();
    }
  };

  return (
    <SelectBox
      testId="sortingSelectBox"
      inputId="selectbox"
      labelText="Sort by"
      className={cx(
        styles.SortAssets___isMobile,
        styles.SortAssets___isDesktop,
      )}
      options={options}
      selectedOveride={selectedOption}
      onChange={handleChange}
    />
  );
}
