import { css } from '@emotion/css';

export const expandable = css`
  border-bottom: 1px solid var(--colors_dark_500);
`;

export const expandable_label = css`
  padding: calc(var(--measurements_gridUnit) * 3.5)
    calc(var(--measurements_gridUnit) * 6);
`;

export const expandable_icon = css`
  margin-right: calc(var(--measurements_gridUnit) * 6);
`;

export const expandable_expandableContent = css`
  padding-top: calc(var(--measurements_gridUnit) * 1);
  padding-left: calc(var(--measurements_gridUnit) * 6);
  padding-right: calc(var(--measurements_gridUnit) * 6);
`;

export const expandable_tickbox = css`
  margin: 0 0 calc(var(--measurements_gridUnit) * 3);
`;
