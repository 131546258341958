const buildUrlWithQueries = (url: string, queries: Record<string, any>) =>
  `${url}?${new URLSearchParams(queries)}`;

// Takes a url and a query object, then returns a url with the queries appended
export const getSwrInfiniteKey = (
  pageIndex: number,
  previousPageData: any | null,
  url: string,
  queryObj: Record<string, any>,
) => {
  // Reached the end of pagination (no more items 'remaining').
  if (previousPageData?.remaining === 0) {
    return null;
  }

  // First page, we don't have `previousPageData`.
  if (pageIndex === 0) {
    // We're mutating the queryObj here, which is not ideal but necessary to add a cursor query to ImmutableXClient.getOrders()
    delete queryObj.cursor;
    return buildUrlWithQueries(url, queryObj);
  }

  // Add the cursor to the API endpoint.
  if (previousPageData?.cursor) {
    queryObj.cursor = previousPageData.cursor;
    return buildUrlWithQueries(url, queryObj);
  }

  // Anything else!
  return null;
};
