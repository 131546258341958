import {
  BaseComponentPropTypes,
  colors,
  Expandable,
  Tickbox,
} from '@imtbl/design-system';
import { useTotalSelectedAmount } from 'hooks/useTotalSelectedAmount.hook';
import { capitalizeFirstLetter } from 'utils/text';

import * as styles from '../CollectionMetadataFilter.styles';
import { expandableCssProps } from './expandableCssProps';

interface DiscretePropTypes extends BaseComponentPropTypes {
  activeFilters: {
    [key: string]: string[];
  };
  join: string;
  onChange: (a, b) => void;
  filter: {
    key: string;
    value: string[];
    type: string;
  };
}

const DiscreteFilter = ({
  testId,
  filter,
  activeFilters,
  join,
  onChange,
}: DiscretePropTypes) => {
  const totalSelectedAmount = useTotalSelectedAmount(filter, activeFilters);

  return (
    <Expandable
      {...expandableCssProps}
      labelText={capitalizeFirstLetter(filter.key)}
      labelBubbleText={totalSelectedAmount}
      fillColor={colors.light[100]}
      data-type={filter.type}
      testId={`${testId}__expandable--discrete`}
    >
      {/*
      @TODO:
      We should not have to sort these values, they should come from the backend
      appropriately sorted.
       */}
      {[...filter.value]
        .sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10))
        .map((option: string) => {
          let checked = false;
          if (
            activeFilters[filter.key] &&
            activeFilters[filter.key].includes(option)
          ) {
            checked = true;
          }
          const tickboxKey = `${filter.key}${join}${option}`;
          return (
            <Tickbox
              key={tickboxKey}
              testId={`${testId}__${tickboxKey}`}
              inputId={tickboxKey}
              labelText={option}
              tickedOveride={checked}
              onChange={onChange}
              fillColor={colors.light[100]}
              className={styles.expandable_tickbox}
            />
          );
        })}
    </Expandable>
  );
};

export default DiscreteFilter;
