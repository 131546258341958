import {
  BaseComponentPropTypes,
  FlexLayout,
  RectangularLoader,
  VerticalSpace,
} from '@imtbl/design-system';
import { useURLSearchParams } from 'hooks/useURLSearchParams.hook';
import { useFilters } from 'marketplace-api/collections';
import { useRouter } from 'next/router';

import BooleanFilter from './filter-controls/BooleanFilter.component';
import DiscreteFilter from './filter-controls/DiscreteFilter.component';
import EnumFilter from './filter-controls/EnumFilter.component';

// @TODO: "continuous" functionality is currently WIP and not
// stable. commenting it out until such time as we're ready to fix it
// import RangeFilter from './filter-controls/RangeFilter.component';
export const FILTER_DATA_DELIMITER = '<|>';
export function CollectionMetadataFilter({ testId }: BaseComponentPropTypes) {
  const urlSearchParams = useURLSearchParams();
  const router = useRouter();
  const { collectionId } = router.query;
  const resolvedCollectionId = (collectionId ||
    urlSearchParams.getCollection()) as string;
  const { filters, filtersLoading } = useFilters(resolvedCollectionId ?? '');
  const activeFilters = urlSearchParams.getFilters() || {};

  // @TODO: "continuous" functionality is currently WIP and not
  // stable. commenting it out until such time as we're ready to fix it
  // const changeContinuous = (data: any, inputId: string) => {
  //   urlSearchParams.updateFilter({
  //     type: 'continuous',
  //     key: inputId,
  //     data,
  //   });
  // };

  const changeBoolean = (checked: boolean, inputId: string) => {
    urlSearchParams.updateFilter({
      type: 'boolean',
      key: inputId,
      checked,
    });
  };

  const changeEnumOrDiscrete = (
    checked: boolean,
    inputId: string,
    type: 'discrete' | 'enum',
  ) => {
    const [key, value] = inputId.split(FILTER_DATA_DELIMITER);
    urlSearchParams.updateFilter({
      type,
      key,
      value,
      checked,
    });
  };

  if (!resolvedCollectionId) {
    return null;
  }

  return filtersLoading ? (
    <VerticalSpace both="medium">
      <RectangularLoader testId={`${testId}__loading`} />
    </VerticalSpace>
  ) : (
    <FlexLayout flexDirection="column" testId={testId}>
      {filters?.map((filter: any) => {
        switch (filter.type) {
          case 'enum':
            return (
              <EnumFilter
                activeFilters={activeFilters}
                filter={filter}
                join={FILTER_DATA_DELIMITER}
                key={filter.key}
                onChange={(e, id) => changeEnumOrDiscrete(e, id, filter.type)}
                testId={testId}
              />
            );

          case 'discrete':
            return (
              <DiscreteFilter
                activeFilters={activeFilters}
                filter={filter}
                join={FILTER_DATA_DELIMITER}
                key={filter.key}
                onChange={(e, id) => changeEnumOrDiscrete(e, id, filter.type)}
                testId={testId}
              />
            );

          case 'boolean':
            return (
              <BooleanFilter
                activeFilters={activeFilters}
                filter={filter}
                join={FILTER_DATA_DELIMITER}
                key={filter.key}
                onChange={changeBoolean}
                testId={testId}
              />
            );

          // @TODO: "continuous" functionality is currently WIP and not
          // stable. commenting it out until such time as we're ready to fix it
          case 'continuous':
          case 'text':
          default:
            return null;
        }
      })}
    </FlexLayout>
  );
}
