import { ImmutableMethodParams } from '@imtbl/imx-sdk';
import { CustomOrderFilters, SortTypeValue } from 'components/SortAssets';
import { useRouter } from 'next/router';
import qs from 'qs';

import { FILTER_DATA_DELIMITER } from '../components/CollectionMetadataFilter/CollectionMetadataFilter.component';

interface SellMetadataBase {
  key: string;
}

interface CurrencyFilter {
  type: 'currency';
  value: string;
}

interface EnumOrDiscreteMetadata extends SellMetadataBase {
  type: 'discrete' | 'enum';
  checked: boolean;
  value: string;
}

interface BooleanMetadata extends SellMetadataBase {
  type: 'boolean';
  checked: boolean;
}

interface ContinuousMetadata extends SellMetadataBase {
  type: 'continuous';
  // @TODO: add types for continuous filters (when we know what they look like)
  data: unknown;
}
export type ImxOrEth = 'imx' | 'eth';
export type ActiveTab = ImxOrEth;

export interface ActiveUrlParams {
  filters?: URLFilterParams;
  sort?: UrlSortParams;
  activeTab?: ActiveTab;
  keywordSearch?: string;
  currencyFilter?: string;
  collection?: string;
  customFilter?: string;
}

export interface UrlSortParams {
  direction: ImmutableMethodParams.ImmutableSortOrder;
  order_by: string;
}

export interface URLFilterParams {
  [key: string]: any;
}

export type ActiveUrlParamKeys =
  | 'filters'
  | 'sort'
  | 'activeTab'
  | 'keywordSearch'
  | 'collection'
  | 'customFilter';

export function parseSearchParams(params: string) {
  return qs.parse(params?.split('?')?.[1], {
    ignoreQueryPrefix: false,
    arrayLimit: 1000,
  }) as any;
}

export function useURLSearchParams() {
  const router = useRouter();

  function getCurrentSearchParams(): ActiveUrlParams {
    // @TODO: because next router now returns query params as
    // a neatly formatted JSON blob (but doesnt stringify for a depth
    // of greater than 1)
    // we need to extract our query params from the entire path. :(

    const params = parseSearchParams(router.asPath);

    return params;
  }

  /**
   * push
   * Calls history.push with provided key/values
   * @param params URL Search Params
   */
  function push(params: ActiveUrlParams) {
    const collectionId = router.query?.collectionId;

    if (collectionId) {
      params['collectionId'] = collectionId;
    }

    router.push({
      query: qs.stringify(params, {
        encode: false,
        arrayFormat: 'brackets',
      }),
    });
  }

  /**
   * set functions:
   * Replaces the provided search key with value
   * @param value URL search key value (typed accordingly)
   */
  function setActiveTab(value: ActiveTab) {
    const params = getCurrentSearchParams();
    params.activeTab = value;
    push(params);
  }

  function setFilters(value: URLFilterParams) {
    const params = getCurrentSearchParams();
    params.filters = value;
    push(params);
  }

  function setSort(value: UrlSortParams) {
    const params = getCurrentSearchParams();
    // now that custom filters are added in the Sort Assets component
    // when updating the sort, we must remove any custom filter as well
    delete params.customFilter;
    params.sort = value;
    push(params);
  }

  function setCollection(value: string) {
    const params = getCurrentSearchParams();
    params.collection = value;
    push(params);
  }

  function setKeywordSearch(value: string) {
    const params = getCurrentSearchParams();
    params.keywordSearch = value;
    push(params);
  }

  function setCurrencyFilter(value: string | undefined) {
    const params = getCurrentSearchParams();
    params.currencyFilter = value;
    push(params);
  }

  function setCustomFilter(filter: CustomOrderFilters) {
    const params = getCurrentSearchParams();

    switch (filter) {
      case CustomOrderFilters.RecentlySold:
        params.sort = {
          order_by: SortTypeValue.DATE_ACQUIRED,
          direction: ImmutableMethodParams.ImmutableSortOrder.desc,
        };
        params.customFilter = CustomOrderFilters.RecentlySold;
        break;
      default:
        break;
    }
    push(params);
  }

  /**
   * get
   * @param key URL search key to retrieve
   * @returns JSON object
   */
  function getActiveTab(): ActiveTab | undefined {
    return getCurrentSearchParams()?.activeTab;
  }

  function getFilters(): URLFilterParams | undefined {
    return getCurrentSearchParams()?.filters;
  }

  function getSort(): UrlSortParams | undefined {
    return getCurrentSearchParams()?.sort;
  }

  function getCollection(): string | undefined {
    return getCurrentSearchParams()?.collection;
  }

  function getKeywordSearch(): string | undefined {
    return getCurrentSearchParams()?.keywordSearch;
  }

  function getCurrencyFilter(): string | undefined {
    return getCurrentSearchParams()?.currencyFilter;
  }

  function getCustomFilter(): string | undefined {
    return getCurrentSearchParams()?.customFilter;
  }

  /**
   * delete
   * @param key URL search key to delete
   */
  function remove(keys: ActiveUrlParamKeys[]) {
    const params = getCurrentSearchParams();
    keys.forEach(key => delete params[key]);
    push(params);
  }

  function handleBoolean(filter: BooleanMetadata) {
    const activeFilters = getCurrentSearchParams().filters || {};
    const { checked, key } = filter;
    const filterKeys = key.split(FILTER_DATA_DELIMITER);
    const [propertyName, trueOrFalse] = filterKeys;
    if (!activeFilters[propertyName]) {
      activeFilters[propertyName] = [];
    }

    if (checked) {
      activeFilters[propertyName].push(trueOrFalse);
    } else {
      activeFilters[propertyName] = activeFilters[propertyName].filter(
        item => item !== trueOrFalse,
      );
    }

    setFilters(activeFilters);
  }

  function handleEnumOrDiscrete(filter: EnumOrDiscreteMetadata) {
    const activeFilters = getCurrentSearchParams().filters || {};
    const { key, value, checked } = filter;

    if (!activeFilters[key]) {
      activeFilters[key] = [];
    }

    if (checked) {
      activeFilters[key].push(value);
    } else {
      const index = activeFilters[key].indexOf(value);
      // remove item from array
      if (index !== -1) {
        activeFilters[key].splice(index, 1);
      }
      // if there are no items left, remove the whole key
      if (activeFilters[key].length <= 0) {
        delete activeFilters[key];
      }
    }

    setFilters(activeFilters);
  }

  function handleCurrency(filter: CurrencyFilter) {
    const params = getCurrentSearchParams();
    const { value } = filter;
    const isETHToken = value === '';

    if (isETHToken) {
      delete params.currencyFilter;
    } else {
      params.currencyFilter = value;
    }

    setCurrencyFilter(params.currencyFilter);
  }

  function updateFilter(
    filter:
      | ContinuousMetadata
      | EnumOrDiscreteMetadata
      | BooleanMetadata
      | CurrencyFilter,
  ) {
    switch (filter.type) {
      case 'discrete':
      case 'enum':
        handleEnumOrDiscrete(filter);
        break;

      case 'boolean':
        handleBoolean(filter);
        break;

      case 'currency':
        handleCurrency(filter);
        break;

      case 'continuous':
      default:
        console.error(
          `Unsupported filter type !!`,
          Error('Unsupported filter type !!'),
        );
        break;
    }
  }

  return {
    delete: remove,
    getActiveTab,
    getCollection,
    getCurrencyFilter,
    getCurrentSearchParams,
    getCustomFilter,
    getFilters,
    getKeywordSearch,
    getSort,
    push,
    setActiveTab,
    setCollection,
    setCurrencyFilter,
    setKeywordSearch,
    setCustomFilter,
    setSort,
    updateFilter,
  };
}
