let win = {
  location: {},
  document: {},
  history: {},
  addEventListener: {},
  removeEventListener: {},
  // eslint-disable-next-line
  scrollTo: (options: any) => {},
};

// NOTE: This is to catch SSR errors.
if (typeof window !== 'undefined') {
  win = window;
}

export const {
  location,
  history,
  document,
  addEventListener,
  removeEventListener,
  scrollTo,
} = win as Window;
