import { ExpandablePropTypes } from '@imtbl/design-system';

import * as styles from '../CollectionMetadataFilter.styles';

export const expandableCssProps = {
  className: styles.expandable,
  labelClassName: styles.expandable_label,
  iconClassName: styles.expandable_icon,
  expandableContentClassName: styles.expandable_expandableContent,
} as Partial<ExpandablePropTypes>;
