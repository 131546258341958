import { ImmutableMethodParams } from '@imtbl/imx-sdk';
import { DefaultSortOption, SortContext } from 'components/SortAssets';
import { nameOptions, priceOptions } from 'components/SortAssets/constants';

export function chooseDefaultSortOptions(context: SortContext) {
  const defaultOption = {
    asString: '',
    order_by: '',
    direction: ImmutableMethodParams.ImmutableSortOrder.desc,
  } as DefaultSortOption;
  defaultOption.asString =
    context === SortContext.MARKETPLACE
      ? priceOptions[0].value
      : nameOptions[0].value;
  const [order_buy, direction] = defaultOption.asString.split('#');
  defaultOption.order_by = order_buy;
  defaultOption.direction =
    direction as ImmutableMethodParams.ImmutableSortOrder;
  return defaultOption;
}
