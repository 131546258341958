import Head from 'next/head';

import { isProdApiEnvironment } from '../../../environment';

export const DEFAULT_IMAGE = 'https://i.ibb.co/c1X0hbw/twitter-preview.jpg';

export interface PageSeoPropTypes {
  title: string;
  description?: string;
  twitterTitle?: string;
  twitterImage?: string;
  twitterDescription?: string;
  noIndex?: boolean;
}

export function PageSeo({
  title,
  description,
  twitterTitle,
  twitterImage,
  twitterDescription,
  noIndex,
}: PageSeoPropTypes) {
  return (
    <Head>
      <title>{title}</title>
      {noIndex || !isProdApiEnvironment ? (
        <meta name="robots" content="noindex" data-testid="robots-metatag" />
      ) : (
        <>
          {description && (
            <meta
              name="description"
              content={description}
              data-testid="page-desc"
            />
          )}
          <meta
            name="twitter:card"
            content="summary"
            data-testid="card-summary"
          />
          {twitterTitle && (
            <meta
              name="twitter:title"
              content={twitterTitle}
              data-testid="card-title"
            />
          )}
          {twitterDescription && (
            <meta
              name="twitter:description"
              content={twitterDescription}
              data-testid="card-description"
            />
          )}
          <meta
            name="twitter:image"
            data-testid="card-image"
            content={twitterImage || DEFAULT_IMAGE}
          />
        </>
      )}
    </Head>
  );
}
