// @TODO: make this function type safe!
export const useTotalSelectedAmount = (filter, activeFilters) => {
  const totalSelected: string[] = [];
  if (filter && activeFilters) {
    filter.value.map(
      option =>
        activeFilters[filter.key] &&
        activeFilters[filter.key].includes(option) &&
        totalSelected.push(activeFilters[filter.key]),
    );
  }
  return totalSelected.length > 0 ? totalSelected.length : undefined;
};
