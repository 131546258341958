/*
 *
 * Sort constants
 *
 */

import { ImmutableMethodParams } from '@imtbl/imx-sdk';

export enum CustomOrderFilters {
  RecentlySold = 'recentlySold',
}
export enum SortContext {
  INVENTORY = 'inventory',
  MARKETPLACE = 'marketplace',
}

export enum SortTypeValue {
  TIMESTAMP = 'timestamp',
  BUY_QUANTITY_WITH_FEES = 'buy_quantity_with_fees',
  NAME = 'name',
  SELL_NAME = 'sell_token_name',
  DATE_ACQUIRED = 'updated_at',
}

export enum OptionLabel {
  LABEL_NAME_RECENTLY_SOLD = 'Recently Sold',
  LABEL_NAME_ASC = 'Name (A-Z)',
  LABEL_LIST_DATE_ASC = 'Date listed (oldest first)',
  LABEL_LIST_DATE_DESC = 'Date listed (newest first)',
  LABEL_PRICE_ASC = 'Price (low-high)',
  LABEL_PRICE_DESC = 'Price (high-low)',
  LABEL_LIST_DATE_ACQUIRE_ASC = 'Date acquired (oldest first)',
  LABEL_LIST_DATE_ACQUIRE_DESC = 'Date acquired (newest first)',
}

export const sellNameOptions = [
  {
    value: `${SortTypeValue.SELL_NAME}#${ImmutableMethodParams.ImmutableSortOrder.asc}`,
    label: OptionLabel.LABEL_NAME_ASC,
  },
];

export const nameOptions = [
  {
    value: `${SortTypeValue.NAME}#${ImmutableMethodParams.ImmutableSortOrder.asc}`,
    label: OptionLabel.LABEL_NAME_ASC,
  },
];

export const dateOptions = [
  {
    value: `${SortTypeValue.TIMESTAMP}#${ImmutableMethodParams.ImmutableSortOrder.desc}`,
    label: OptionLabel.LABEL_LIST_DATE_DESC,
  },
  {
    value: `${SortTypeValue.TIMESTAMP}#${ImmutableMethodParams.ImmutableSortOrder.asc}`,
    label: OptionLabel.LABEL_LIST_DATE_ASC,
  },
];

export const dateAcquiredOptions = [
  {
    value: `${SortTypeValue.DATE_ACQUIRED}#${ImmutableMethodParams.ImmutableSortOrder.desc}`,
    label: OptionLabel.LABEL_LIST_DATE_ACQUIRE_DESC,
  },
  {
    value: `${SortTypeValue.DATE_ACQUIRED}#${ImmutableMethodParams.ImmutableSortOrder.asc}`,
    label: OptionLabel.LABEL_LIST_DATE_ACQUIRE_ASC,
  },
];

export const priceOptions = [
  {
    value: `${SortTypeValue.BUY_QUANTITY_WITH_FEES}#${ImmutableMethodParams.ImmutableSortOrder.asc}`,
    label: OptionLabel.LABEL_PRICE_ASC,
  },
  {
    value: `${SortTypeValue.BUY_QUANTITY_WITH_FEES}#${ImmutableMethodParams.ImmutableSortOrder.desc}`,
    label: OptionLabel.LABEL_PRICE_DESC,
  },
];

export const recentlySoldFilterOptions = [
  {
    value: `${SortTypeValue.DATE_ACQUIRED}#${ImmutableMethodParams.ImmutableSortOrder.desc}#${CustomOrderFilters.RecentlySold}`,
    label: OptionLabel.LABEL_NAME_RECENTLY_SOLD,
  },
];
