import {
  BaseComponentPropTypes,
  colors,
  Expandable,
  Tickbox,
} from '@imtbl/design-system';
import { useTotalSelectedAmount } from 'hooks/useTotalSelectedAmount.hook';
import { capitalizeFirstLetter } from 'utils/text';

import * as styles from '../CollectionMetadataFilter.styles';
import { expandableCssProps } from './expandableCssProps';
interface EnumPropTypes extends BaseComponentPropTypes {
  activeFilters: {
    [key: string]: string;
  };
  join: string;
  onChange: (a, b) => void;
  filter: {
    key: string;
    value: string[];
    type: string;
  };
}

const EnumFilter = ({
  filter,
  testId,
  activeFilters,
  onChange,
  join,
}: EnumPropTypes) => {
  const totalSelectedAmount = useTotalSelectedAmount(filter, activeFilters);
  return (
    <Expandable
      labelText={capitalizeFirstLetter(filter.key)}
      labelBubbleText={totalSelectedAmount}
      key={filter.key}
      fillColor={colors.light[100]}
      data-type={filter.type}
      testId={`${testId}__expandable--enum`}
      {...expandableCssProps}
    >
      {filter.value.map((option: string) => {
        const displayValue = capitalizeFirstLetter(option);
        const checked =
          !!activeFilters[filter.key] &&
          activeFilters[filter.key].includes(option);
        const tickboxKey = `${filter.key}${join}${option}`;
        return (
          <Tickbox
            key={tickboxKey}
            testId={`${testId}__${tickboxKey}`}
            inputId={tickboxKey}
            labelText={displayValue}
            tickedOveride={checked}
            onChange={onChange}
            fillColor={colors.light[100]}
            className={styles.expandable_tickbox}
            data-type={filter.type}
          />
        );
      })}
    </Expandable>
  );
};

export default EnumFilter;
