import { FEATURE_FLAG } from 'config/feature-flags';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { errorLogger } from 'utils/error-logger';
import { getSwrInfiniteKey } from 'utils/getSwrInfiniteKey';
import { convertCommaListToStringArray } from 'utils/parser';

import { API_URL, PAGE_SIZE, SWR_SETTINGS } from '../api/constants';
import { fetcher } from '../api/fetch';
import { useURLSearchParams } from '../hooks/useURLSearchParams.hook';
import { MARKETPLACE_API_URL } from './constants';
import {
  BuildCollectionApiUrlProps,
  GetCollectionsApiResponse,
  MarketplaceApiCollection,
  MarketplaceCollectionResponse,
} from './types';

export const DEFAULT_COLLECTION_SORT_PROPS = {
  sortBy: 'name',
  direction: 'asc',
};

const generateCollectionApiUrl = ({
  sortBy = DEFAULT_COLLECTION_SORT_PROPS.sortBy,
  direction = DEFAULT_COLLECTION_SORT_PROPS.direction,
  blackList = '',
  whiteList = '',
  pageSize = PAGE_SIZE || '48',
  keywordSearch = '',
  cursor = undefined,
  apiHost = API_URL,
}: BuildCollectionApiUrlProps) => {
  // @NOTE: the new marketplace backend implements blacklisting and whitelisting internally,
  // so we no longer need to send these lists as part of the query params
  const isPublicApi = !apiHost.includes('marketplace-api');
  const black =
    isPublicApi && blackList.length
      ? `&blacklist=${convertCommaListToStringArray(blackList)}`
      : '';
  const white =
    isPublicApi && whiteList.length
      ? `&whitelist=${convertCommaListToStringArray(whiteList)}`
      : '';
  const optionalCursor = cursor ? `&cursor=${cursor}` : '';
  const optionalSearch = keywordSearch ? `&keyword=${keywordSearch}` : '';
  const base = `${apiHost}/collections?page_size=${pageSize}&order_by=${sortBy}&direction=${direction}`;
  return base + black + white + optionalCursor + optionalSearch;
};

export const fetchCollections = async (
  {
    sortBy,
    direction,
    blackList,
    whiteList,
    keywordSearch,
    pageSize,
    cursor,
    apiHost,
  }: BuildCollectionApiUrlProps,
  imxApiKey?: string,
) => {
  const requestUrl = generateCollectionApiUrl({
    sortBy,
    direction,
    blackList,
    whiteList,
    keywordSearch,
    cursor,
    pageSize,
    apiHost,
  });
  return fetcher(requestUrl, {
    headers: { ...(imxApiKey ? { 'x-api-key': imxApiKey } : {}) },
  }) as Promise<GetCollectionsApiResponse>;
};

export function useCollections({
  pageSize,
  sortBy,
  direction,
}: {
  pageSize?: number;
  sortBy?: string;
  direction?: string;
} = {}) {
  // Only display collections that are not blacklisted from our homepage
  // https://immutable.atlassian.net/browse/IMX-4329
  const blackListedCollectionFlag = useFeatureFlag<string>(
    FEATURE_FLAG.HOMEPAGE_COLLECTIONS_BLACKLIST,
  );
  const useNewMarketplaceApi = useFeatureFlag<string>(
    FEATURE_FLAG.ENABLE_NEW_HOMEPAGE,
  );

  const urlSearchParams = useURLSearchParams();
  const keywordSearch = urlSearchParams.getKeywordSearch();
  const queries: Record<string, string> = {
    ...(keywordSearch && { keywordSearch }),
    ...(blackListedCollectionFlag && {
      blackList: blackListedCollectionFlag,
      pageSize: pageSize?.toString() || undefined,
      apiHost: useNewMarketplaceApi ? MARKETPLACE_API_URL : API_URL,
      sortBy,
      direction,
    }),
  };
  const searchParams = new URLSearchParams(queries);
  const collectionsUrl = `${queries.apiHost}/collections?${searchParams}`;

  const {
    data,
    error,
    isValidating,
    size: pagesCount, // Total number of pages.
    setSize: setPagesCount, // Add +1 to size to load the next page.
  } = useSWRInfinite(
    (...args) => getSwrInfiniteKey(...args, collectionsUrl, queries),
    () => fetchCollections(queries),
    SWR_SETTINGS,
  );

  if (error) {
    errorLogger('Failed to fetch collections', error);
  }

  // NOTE: 'useSWRInfinite' returns an array of objects for each page,
  // reduce it here into single object so the consumer doesn't have to.
  const collections = data?.reduce(
    (accum: GetCollectionsApiResponse, current: GetCollectionsApiResponse) => ({
      result: [...accum?.result, ...current?.result],
      remaining: current?.remaining,
      cursor: current.cursor,
    }),
    {
      result: [],
      remaining: 0,
      cursor: '',
    },
  );

  return {
    collectionsValidating: isValidating,
    collections: collections?.result,
    haveRemaining: !!collections?.remaining,
    collectionsError: error,
    pagesCount,
    setPagesCount,
  };
}

export function useCollection(
  collectionId: string,
  initialData?: MarketplaceApiCollection,
) {
  const config = {
    ...SWR_SETTINGS,
    fallbackData: initialData,
  };

  const useNewMarketplaceApi = useFeatureFlag<string>(
    FEATURE_FLAG.ENABLE_NEW_HOMEPAGE,
  );

  const { data, mutate, error } = useSWR<MarketplaceCollectionResponse>(
    collectionId
      ? `${
          useNewMarketplaceApi ? MARKETPLACE_API_URL : API_URL
        }/collections/${collectionId}`
      : null,
    fetcher,
    config,
  );

  if (error) {
    errorLogger(
      `Failed to fetch specific collectionId: ${collectionId}`,
      error,
    );
  }

  return { data, mutate, error };
}

export function useFilters(collectionId: string) {
  const { data, error } = useSWR(
    collectionId ? `${API_URL}/collections/${collectionId}/filters` : null,
    fetcher,
  );

  if (error) {
    errorLogger(
      `Failed to fetch filters for collectionId: ${collectionId}`,
      error,
    );
    return {};
  }

  // Handling inconsistent return from API
  if (data?.error || data?.message) {
    return {
      filters: [],
      filtersError: data,
    };
  }

  return {
    filters: data,
    filtersError: error,
    filtersLoading: !data && !error,
  };
}
