import { css } from '@emotion/css';
import { styleHelpers } from '@imtbl/design-system';

export const SortAssets___isMobile = css`
  width: 100%;
`;
export const SortAssets___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'small',
    `
  flex-grow: 1;
  max-width: 300px;
`,
  );
