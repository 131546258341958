import {
  BaseComponentPropTypes,
  colors,
  Expandable,
  Tickbox,
} from '@imtbl/design-system';
import { useTotalSelectedAmount } from 'hooks/useTotalSelectedAmount.hook';
import { capitalizeFirstLetter } from 'utils/text';

import * as styles from '../CollectionMetadataFilter.styles';
import { expandableCssProps } from './expandableCssProps';

interface BooleanPropTypes extends BaseComponentPropTypes {
  activeFilters: {
    [key: string]: string[];
  };
  join: string;
  onChange: (a, b) => void;
  filter: {
    key: string;
    value: string[];
    type: string;
  };
}

const BooleanFilter = ({
  filter,
  activeFilters,
  join,
  testId,
  onChange,
}: BooleanPropTypes) => {
  let checkedA = false;
  let checkedB = false;
  if (activeFilters[filter.key]?.includes('true')) {
    checkedA = true;
  }
  if (activeFilters[filter.key]?.includes('false')) {
    checkedB = true;
  }
  const trueTickboxKey = `${filter.key}${join}true`;
  const falseTickboxKey = `${filter.key}${join}false`;
  const totalSelectedAmount = useTotalSelectedAmount(filter, activeFilters);

  return (
    <Expandable
      labelText={capitalizeFirstLetter(filter.key)}
      labelBubbleText={totalSelectedAmount}
      key={filter.key}
      fillColor={colors.light[100]}
      data-type={filter.type}
      testId={`${testId}__expandable--boolean`}
      {...expandableCssProps}
    >
      <Tickbox
        key={trueTickboxKey}
        inputId={trueTickboxKey}
        testId={`${testId}__${trueTickboxKey}`}
        labelText="True"
        tickedOveride={checkedA}
        onChange={onChange}
        fillColor={colors.light[100]}
        className={styles.expandable_tickbox}
      />
      <Tickbox
        key={falseTickboxKey}
        inputId={falseTickboxKey}
        testId={`${testId}__${falseTickboxKey}`}
        labelText="False"
        tickedOveride={checkedB}
        onChange={onChange}
        fillColor={colors.light[100]}
        className={styles.expandable_tickbox}
      />
    </Expandable>
  );
};

export default BooleanFilter;
